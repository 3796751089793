(function () {
    var method;
    var noop = function () {
    };
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', '    profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

// Inject CSRF-Token in all Ajax Requests
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

// Sweet Alert Replacement for data-confirm links
$.rails.confirmed = function (link) {
    link.removeAttr('data-confirm');
    link.trigger('click.rails');
};

$.rails.showConfirmDialog = function (link) {
    sweetAlert({
        title: link.attr('data-confirm'),
        showCancelButton: true,
        cancelButtonText: 'Abbrechen'
    }, function (isConfirmed) {
        if (isConfirmed)
            $.rails.confirmed(link);
    });
};


// Ajax Filter and Pagination
var $ajaxRemoteForm = $('form[data-remote]');
$ajaxRemoteForm.oldVals = '';
var $ajaxFilteredContent = $('#ajax-filtered-content');

var isAjaxFilteredContent = function() {
    return $ajaxFilteredContent.length == 1;
};

if (isAjaxFilteredContent()) {

    var showSpinner = function() {
        var spinner = '<div class="spinner-loader">Loading…</div>';
        $ajaxFilteredContent.html(spinner);
    };

    var refreshContent = function(data) {
        $ajaxFilteredContent.html(data);
    };

    $('form[data-remote]').on('keypress change input propertychange', function () {
        if($ajaxRemoteForm.oldVals !== $ajaxRemoteForm.serialize()){
            $ajaxRemoteForm.oldVals = $ajaxRemoteForm.serialize();
            $ajaxRemoteForm.submit();
        }
    });

    $ajaxRemoteForm.on('ajax:before', function() {
        showSpinner();
    });

    $ajaxRemoteForm.on('ajax:error', function(xhr, status, error) {
        var html = '<div class="alert alert-danger" role="alert">' +
            'Ein Fehler bei der Filterung ist aufgetreten. ' +
            'Bitte versuchen Sie es erneut</div>';
        $ajaxFilteredContent.html(html);
    });

    $ajaxRemoteForm.on('ajax:success', function(event, data, status, xhr) {
        refreshContent(data);
    });

    $(document).on('click', '.pagination a', function (e) {
        e.preventDefault();
        $.ajax({
            url: $(this).attr('href'),
            dataType: 'html',
            type: 'get',
            beforeSend: function() {
                showSpinner();
            },
            success: function (data) {
                refreshContent(data);
            }
        });
    });

    /**
     * Export Button bekommen on the fly die Filter mit
     * ansonsten würde man immer die ganze Ergebnismenge bekommen
     */
    $(document).on('click', '.export-button', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        url = url+'?'+$ajaxRemoteForm.serialize();
        location.href = url;
    });

    $(document).on('click', 'button[type="reset"]', function (e) {
        e.preventDefault();
        var url = $(this).closest('form').attr('action');
        location.href = url;
    });
}


var flashNotification = function(level, message) {

    var source   = $("#bootstrap-alert-template").html();
    var template = Handlebars.compile(source);
    var html    = template({level: level, message: message});

    $('.page-content').prepend(html);

    return html;
};


$('.company_switcher').on('change', function (event) {
    var $element = $(event.currentTarget);
    var switchUrl = $element.find('option:selected').data('switch-url');
    location.href = switchUrl;
});

// Confirm Dialog mit Sweet Alert triggern
$.rails.allowAction = function (link) {
    if (!link.attr('data-confirm')) {
        return true;
    }
    else {
        $.rails.showConfirmDialog(link);
        return false;
    }
};



$('.colorpicker').colorpicker();

$('body').append('<div class="width"></div>');

function getLang() {
    return $('html').attr('lang');
}

function getWidth() {
    return parseInt($('.width').css('z-index'));
}

/**
 * Methode um die externe Api aufzurufen
 *
 * @param string path
 * @param object data
 */
var apiGet = function(path, data) {
    $.extend(data, data, { token: App.apiToken });
    return $.ajax({
        dataType: 'jsonp',
        url: App.baseApiUri + path,
        data: data,
        method: 'GET'
    });
};

/**
 * Updated eine Selectbox mit Daten
 *
 * @param string id
 * @param array data
 * @param string selected
 */
var updateSelectboxWithData = function(id, data, selected) {

    $(id).find('option').not(':first').remove();

    $.each(data, function (index, item) {
        $(id).append($('<option/>', {
            value: item.id,
            text : item.name,
            selected: selected === item.id
        }));
    });
};

var xhrToJson = function(xhr) {
    return $.parseJSON(xhr.responseText);
};

(function() {

    /**
     * Überwacht anhand von data-attributen die dependsOn Abhängikeiten
     * und blendet automatisch Felder, Werte, Markup ein und aus
     *
     * siehe Bestellung resources/views/order
     *
     * @param element
     */
    var dependOnUiObserve = function(element) {

        var $element = $(element);
        var fieldToCheck;

        if ($(element).data('name')) {
            fieldToCheck = $(element).data('name');
            fieldToCheck = "[name='" + fieldToCheck + "']";
        } else {
            fieldToCheck = $(element).data('id');
            fieldToCheck = "#" + fieldToCheck;
        }

        var type = $(fieldToCheck).attr('type');

        var dependType = 'values';

        if ($(element).data('qualifier')) {
            dependType = $(element).data('qualifier');
        } else {
            if (type == 'checkbox') {
                dependType = 'checked'
            }
        }

        var value = $element.data('value');

        if (dependType === 'match') {
            value = RegExp(value);
        }

        // Uns interessieren nur Strings als Werte innerhalb eines Arrays
        if ($.isArray(value)) {
            value = $.map(value, function(item, index) {
                return item.toString();
            });
        }

        params = {};
        params[fieldToCheck] = {};
        params[fieldToCheck][dependType] = value;

        $element.dependsOn(params, {
            onEnable: function() {
                $(window).trigger('resize');
            },
            onDisable: function() {
                $(window).trigger('resize');
            },
            duration: 0
        });

    };


    $('[data-depends-on]').each(function(index, element) {
        dependOnUiObserve(element);
    });

}).call(this);

/* -------------------------------------------------------------------------- */
/* > Preisrechner: Verbrauchs-Slider */

var $calcUsageSlider = $(".form-module--usage-slider");

if ($calcUsageSlider.length) {

    $calcUsageSlider.usageField = $calcUsageSlider.find("[name='usage']");
    $calcUsageSlider.silhouettesContainer = $calcUsageSlider.find(".usage-slider--silhouettes");
    $calcUsageSlider.sliderControls = $calcUsageSlider.find(".usage-slider--control");
    $calcUsageSlider.sliderControls.strom = $calcUsageSlider.sliderControls.filter("[data-sparte='strom']");
    $calcUsageSlider.sliderControls.gas = $calcUsageSlider.sliderControls.filter("[data-sparte='gas']");
    $calcUsageSlider.sliderArrow = $calcUsageSlider.find(".usage-slider--arrow");
    $calcUsageSlider.presetLabels = $calcUsageSlider.silhouettesContainer.find("label");
    $calcUsageSlider.presetRadios = $calcUsageSlider.silhouettesContainer.find(":radio");
    $calcUsageSlider.presetRadios.strom = $calcUsageSlider.presetRadios.filter("[name$='strom']");
    $calcUsageSlider.presetRadios.gas = $calcUsageSlider.presetRadios.filter("[name$='gas']");
    $calcUsageSlider.sliderRange = {
        'strom': {
            'min': parseInt($calcUsageSlider.usageField.data("strom-min")),
            '6.3%': parseInt($calcUsageSlider.presetRadios.strom.eq(0).data('value')),
            '31.1%': parseInt($calcUsageSlider.presetRadios.strom.eq(1).data('value')),
            '60.3%': parseInt($calcUsageSlider.presetRadios.strom.eq(2).data('value')),
            '88.5%': parseInt($calcUsageSlider.presetRadios.strom.eq(3).data('value')),
            'max': parseInt($calcUsageSlider.usageField.data("strom-max"))
        },
        'gas': {
            'min': parseInt($calcUsageSlider.usageField.data("gas-min")),
            '8.9%': parseInt($calcUsageSlider.presetRadios.gas.eq(0).data('value')),
            '34.7%': parseInt($calcUsageSlider.presetRadios.gas.eq(1).data('value')),
            '62.5%': parseInt($calcUsageSlider.presetRadios.gas.eq(2).data('value')),
            '88.3%': parseInt($calcUsageSlider.presetRadios.gas.eq(3).data('value')),
            'max': parseInt($calcUsageSlider.usageField.data("gas-max"))
        },
        'strom-business': {
            'min': parseInt($calcUsageSlider.usageField.data("strom-business-min")),
            '6.3%': parseInt($calcUsageSlider.presetRadios.strom.eq(0).data('value-business')),
            '31.1%': parseInt($calcUsageSlider.presetRadios.strom.eq(1).data('value-business')),
            '60.3%': parseInt($calcUsageSlider.presetRadios.strom.eq(2).data('value-business')),
            '88.5%': parseInt($calcUsageSlider.presetRadios.strom.eq(3).data('value-business')),
            'max': parseInt($calcUsageSlider.usageField.data("strom-business-max"))
        },
        'gas-business': {
            'min': parseInt($calcUsageSlider.usageField.data("gas-business-min")),
            '8.9%': parseInt($calcUsageSlider.presetRadios.gas.eq(0).data('value-business')),
            '34.7%': parseInt($calcUsageSlider.presetRadios.gas.eq(1).data('value-business')),
            '62.5%': parseInt($calcUsageSlider.presetRadios.gas.eq(2).data('value-business')),
            '88.3%': parseInt($calcUsageSlider.presetRadios.gas.eq(3).data('value-business')),
            'max': parseInt($calcUsageSlider.usageField.data("gas-business-max"))
        }
    };

    $calcUsageSlider.getResetVal = function(branch) {
        var resetVal = $calcUsageSlider.getSliderVal(branch);
        if ($calcUsageSlider.usageField.is("[data-old]")) {
            var oldVal = parseInt($calcUsageSlider.usageField.data("old"));
            if (!isNaN(oldVal)) {
                resetVal = oldVal;
            }
            $calcUsageSlider.usageField.removeAttr("data-old");
        }
        return resetVal;
    };

    $calcUsageSlider.setBranch = function(branch) {
        var resetVal = $calcUsageSlider.getResetVal(branch);
        $calcUsageSlider.attr("data-sparte", branch);
        $calcUsageSlider.usageField.setMinMax();
        $calcUsageSlider.setSliderVal(resetVal);
        $calcUsageSlider.setCustomerType();
    };

    $calcUsageSlider.getBranch = function() {
        return $calcUsageSlider.attr("data-sparte");
    };

    $calcUsageSlider.isCompany = function() {
        return $('[name="company"]').is(':checked');
    };

    $calcUsageSlider.setCustomerType = function() {
        console.log('set customer type');
        var val = $calcUsageSlider.getUsage();
        var branch = $calcUsageSlider.getBranch();
        var isCompany = $calcUsageSlider.isCompany();
        console.log(isCompany);

        if(val > $calcUsageSlider.sliderRange[branch + (isCompany ? '-business' : '')].max || val < $calcUsageSlider.sliderRange[branch + (isCompany ? '-business' : '')].min) {
            val = parseInt($calcUsageSlider.presetRadios[branch].filter(":first").data('value' + (isCompany ? '-business' : '')));
        }

        $calcUsageSlider.usageField.setMinMax();
        $calcUsageSlider.setSliderVal(val);
        $calcUsageSlider.setUsage(val);
        console.log($calcUsageSlider.sliderRange[branch + (isCompany ? '-business' : '')]);
        $calcUsageSlider.sliderControls[branch][0].noUiSlider.updateOptions({
            range: $calcUsageSlider.sliderRange[branch + (isCompany ? '-business' : '')]
        }, true);
    };

    $('[name="company"]').on('ifChanged blur click keydown keyup change', $calcUsageSlider.setCustomerType);

    $calcUsageSlider.setUsage = function(usageVal) {
        $calcUsageSlider.usageField.val(usageVal);
    };

    $calcUsageSlider.getUsage = function() {
        return parseInt($calcUsageSlider.usageField.val());
    };

    $calcUsageSlider.getSliderVal = function(branch) {
        if ($.type(branch) === "undefined") {
            branch = $calcUsageSlider.getBranch();
        }
        var sliderVal = $calcUsageSlider.sliderControls[branch][0].noUiSlider.get();
        return Math.round(sliderVal);
    };

    $calcUsageSlider.setSliderVal = function(sliderVal) {
        var branch = $calcUsageSlider.getBranch();
        $calcUsageSlider.sliderControls[branch][0].noUiSlider.set(sliderVal);
    };

    $calcUsageSlider.applySliderVal = function() {
        var sliderVal = $calcUsageSlider.getSliderVal();
        $calcUsageSlider.setUsage(sliderVal);
    };

    $calcUsageSlider.implantArrowSVG = function() {
        var branch = $calcUsageSlider.getBranch();
        var $sliderHandle = $calcUsageSlider.sliderControls[branch].find(".noUi-handle");
        if (!$sliderHandle.has("svg").length) {
            var $svgArrow = $calcUsageSlider.sliderArrow.find("svg").clone();
            $sliderHandle.append($svgArrow);
        }
    };

    $calcUsageSlider.setActiveSilhouette = function() {
        var branch = $calcUsageSlider.getBranch();
        var sliderVal = $calcUsageSlider.getSliderVal();
        var presetVal;
        $calcUsageSlider.presetRadios[branch].each(function() {
            presetVal = parseInt($(this).data('value' + ($calcUsageSlider.isCompany() ? '-business' : '')));
            $(this).prop("checked", false);
            if (sliderVal >= presetVal) {
                $(this).prop("checked", true);
            }
        });
    };

    $calcUsageSlider.usageField.setMinMax = function() {
        var branch = $calcUsageSlider.getBranch();
        var $self = $calcUsageSlider.usageField;
        if($calcUsageSlider.isCompany()) {
            $self.attr({
                'min': $self.data(branch + "-business-min"),
                'max': $self.data(branch + "-business-max")
            });
        } else {
            $self.attr({
                'min': $self.data(branch + "-min"),
                'max': $self.data(branch + "-max")
            });
        }

    };

    $calcUsageSlider.usageField.on("blur", function() {
        var usageVal = $calcUsageSlider.getUsage();
        $calcUsageSlider.setSliderVal(usageVal);
    });

    $calcUsageSlider.presetLabels.tooltip({
        'container': '.page-content'
    });

    $calcUsageSlider.presetRadios.on("click", function() {
        var $radio = $(this);
        var presetValue = $radio.data('value' + ($calcUsageSlider.isCompany() ? '-business' : ''));
        $calcUsageSlider.setUsage(presetValue);
        $calcUsageSlider.setSliderVal(presetValue);
    });

    $.each(["strom", "gas"], function(i, branch) {
        noUiSlider.create($calcUsageSlider.sliderControls[branch][0], {
            'start': (function() {
                var usage = $calcUsageSlider.getUsage();
                if (isNaN(usage)) {
                    usage = $calcUsageSlider.presetRadios[branch].filter(":first").data('value' + ($calcUsageSlider.isCompany() ? '-business' : ''));
                }
                return usage;
            })(),
            'connect': 'lower',
            'range': $calcUsageSlider.sliderRange[branch]
        }).on("update", function() {
            $calcUsageSlider.implantArrowSVG();
            $calcUsageSlider.setActiveSilhouette();
            $calcUsageSlider.applySliderVal();
        });
    });

}

function disableInputfields(obj, disable){
    $(obj).find('input').prop('disabled', disable);
    $(obj).find('select').prop('disabled', disable);
    $(obj).find('textarea').prop('disabled', disable);
}

/* < Preisrechner: Verbrauchs-Slider */
/* -------------------------------------------------------------------------- */
// Preisrechner
if ($('body.backend-calculator-create').length) {

    autocompleteCityAndStreetsByPostcode({
        postcode: $('#postcode'),
        street: $('#street'),
        city: $('#city')
    });

    $('select[name="product_id"]').change(function () {
        var $self = $(this);
        var $selectedOption = $self.find("option:selected");
        var $bioProduct = $("#bio-product");
        var name = $self.val();
        var branch = $selectedOption.data("branch");
        var all = ['strom', 'gas'];
        if ($.inArray(name, all) !== -1) {
            $bioProduct.removeClass('hidden');
        } else {
            $bioProduct.addClass('hidden');
        }
        if ($calcUsageSlider.length) {
            $calcUsageSlider.setBranch(branch);
        }
    }).trigger('change');

    $('select[name="street"]').change(function () {
        var $extra = $('#extra');
        var $selectedOption = $(this).find("option:selected");
        if ($selectedOption.val() == 'street_extra') {
            $extra.removeClass('hidden');
            disableInputfields($extra, false);
        } else {
            $extra.addClass('hidden');
            disableInputfields($extra, true);
        }
    }).trigger('change');
}

// Bestellformular
if ($('body.backend-orders-create').length) {
    autocompleteCityAndStreetsByPostcode({
        postcode: $('#billing_postcode'),
        street: $('#billing_street'),
        city: $('#billing_city')
    });
    autocompleteBankname();

    $('select[name="salutation_id"]').change(function () {

        $('.personal .company, .personal .group, .personal .person, .personal .persontrade, .personal .companytrade').addClass('hidden');
        disableInputfields($('.personal .company, .personal .group, .personal .person, .personal .persontrade, .personal .companytrade'), true);
        var name = $('select[name="salutation_id"] option:selected').data('name');
        var check = ['herr', 'frau', 'weg', 'sonstige', 'eheleute', 'familie', 'an', 'lebensgemeinschaft'];
        var checktrade = ['herr', 'frau', 'weg', 'sonstige', 'eheleute', 'lebensgemeinschaft'];

        if (check.indexOf(name) != -1) {
            $('.personal .person').removeClass('hidden');
            disableInputfields($('.personal .person'), false);
        } else if (name == 'firma') {
            $('.personal .company').removeClass('hidden');
            disableInputfields($('.personal .company'), false);
        }

        if (checktrade.indexOf(name) != -1) {
            $('.personal .persontrade').removeClass('hidden');
            disableInputfields($('.personal .persontrade'), false);
        } else if (name == 'firma' || name == 'familie' || name == 'an') {
            $('.personal .companytrade').removeClass('hidden');
            disableInputfields($('.personal .companytrade'), false);
        }

    }).trigger('change');

    /* eof persönliche information */

    /* bestellinformation */

    $('select[name="order_type_id"]').change(function () {

        $('.order .new, .order .change').addClass('hidden');
        disableInputfields($('.order .new, .order .change'), true);

        var name = $('select[name="order_type_id"] option:selected').data('name');

        if (name == 'wechsel') {

            $('.order .change').removeClass('hidden');
            disableInputfields($('.order .change'), false);
        } else if (name == 'umzug') {

            $('.order .new').removeClass('hidden');
            disableInputfields($('.order .new'), false);
        }
        $('input[name="next_available_date"]').trigger('ifCreated');
        $('input[name="is_cancelled_previous_supplier"]').trigger('ifCreated');
    }).trigger('change');

    /* eof bestellinformation */

    /* rechnungsadresse */

    $('select[name="is_billing_address_different"]').change(function () {

        $('.shipping .other').addClass('hidden');
        disableInputfields($('.shipping .other'), true);

        if ($('option[value="1"]', $(this)).is(':selected')) {

            $('.shipping .other').removeClass('hidden');
            disableInputfields($('.shipping .other'), false);
        }

    }).trigger('change');

    /* eof rechnungsadresse */

    /* zahlungsinformation */

    $('select[name="payment_method_id"]').change(function () {

        $('.payment .debit').addClass('hidden');
        disableInputfields($('.payment .debit'), true);
        var name = $('select[name="payment_method_id"] option:selected').data('name');

        if (name == 'bankeinzug') {

            $('.payment .debit').removeClass('hidden');
            disableInputfields($('.payment .debit'), false);
        } else {

            $('.payment .sepa, .payment .normal').addClass('hidden');
            disableInputfields($('.payment .sepa, .payment .normal'), false);
        }

    }).trigger('change');

    $('select[name="billing_street"]').change(function () {
        var $extra = $('#extra');
        var $selectedOption = $(this).find("option:selected");
        if ($selectedOption.val() == 'street_extra') {
            $extra.removeClass('hidden');
            disableInputfields($extra, false);
        } else {
            $extra.addClass('hidden');
            disableInputfields($extra, true);
        }
    }).trigger('change');

    $('select[name="has_kwk"]').change(function () {
        var $extra = $('.premiums');
        var $selectedOption = $(this).find("option:selected");
        if ($selectedOption.val() == '1') {
            $extra.removeClass('hidden');
            disableInputfields($extra, false);
        } else {
            $extra.addClass('hidden');
            disableInputfields($extra, true);
        }
    }).trigger('change');
}

/* Bestellformular */

/* persönliche information */
$('input[name="offline"]').on('ifChecked', function () {
    $('.offline-additional').removeClass('hidden');
    $('.offline-additional select').trigger('change');
}).on('ifUnchecked', function () {
    $('.offline-additional').addClass('hidden');
    $('.offline-additional-city').addClass('hidden');
}).on('ifCreated', function () {
    if ($('input[name="offline"]').prop('checked') === true) {
        $('input[name="offline"]').trigger('ifChecked');
    } else {
        $('input[name="offline"]').trigger('ifUnchecked');
    }
});

$('.offline-additional select').on('change', function () {
    if ($.inArray($(this).val(), ['regional_distribution', 'local_distribution']) >= 0) {
        $('.offline-additional-city').removeClass('hidden');
    } else {
        $('.offline-additional-city').addClass('hidden');
    }
}).trigger('change');

$('.offline-additional-city').delegate('input.cities', 'blur', function () {

    var inputs = $('input.cities');
    var allFilled = true;
    var empty_inputs = [];
    $.each(inputs, function (index, element) {
        var value = $(element).val();
        if (!$.trim(value).length) {
            empty_inputs.push($(element));
            allFilled = false;
        }
    });

    if (empty_inputs.length > 1) {
        $.each(empty_inputs, function (index, element) {
            if (index < empty_inputs.length - 1) {
                $(element).closest('.form-group').remove();
            }
        });
    }
    if (inputs.length < 10 && allFilled) {
        var cloned = $(this).closest('.form-group').clone();
        cloned.find("input").val("");
        cloned.appendTo($(this).closest('.form-group').parent());
    }
});
$('.offline-additional-city input').trigger('blur');

$('input[name="online"]').on('ifChecked', function () {
    $('.online-additional-website').removeClass('hidden');
    $('.online-additional-marketingchannels').removeClass('hidden');
}).on('ifUnchecked', function () {
    $('.online-additional-website').addClass('hidden');
    $('.online-additional-marketingchannels').addClass('hidden');
}).on('ifCreated', function () {
    if ($('input[name="online"]').prop('checked') === true) {
        $('input[name="online"]').trigger('ifChecked');
    } else {
        $('input[name="online"]').trigger('ifUnchecked');
    }
});

// Vorlieferant bereits gekündigt
$('input[name="is_cancelled_previous_supplier"]').on('ifChecked', function () {
    $('#cancelled_at').prop("disabled",false);
    $('#cancelled_at').parents('.form-group').removeClass('hidden');
}).on('ifUnchecked', function () {
    $('#cancelled_at').prop("disabled",true);
    $('#cancelled_at').parents('.form-group').addClass('hidden');
}).on('ifCreated', function () {
    if ($('input[name="is_cancelled_previous_supplier"]').prop('checked') === true) {
        $('input[name="is_cancelled_previous_supplier"]').trigger('ifChecked');
    } else {
        $('input[name="is_cancelled_previous_supplier"]').trigger('ifUnchecked');
    }
});

$('input[name="next_available_date"]').on('ifChecked', function () {
    $('#desired_at').prop("disabled",true);
    $('#desired_at').parents('.change').addClass('hidden');
}).on('ifUnchecked', function () {
    $('#desired_at').prop("disabled",false);
    if($('[name="order_type_id"]').val() == 1){
        $('#desired_at').parents('.change').removeClass('hidden');
    }
}).on('ifCreated', function () {
    if ($('input[name="next_available_date"]').prop('checked') === true) {
        $('input[name="next_available_date"]').trigger('ifChecked');
    } else {
        $('input[name="next_available_date"]').trigger('ifUnchecked');
    }
});

$('.online-additional-website').delegate('input.websites', 'blur', function () {

    var inputs = $('input.websites');
    var allFilled = true;
    var empty_inputs = [];
    $.each(inputs, function (index, element) {
        var value = $(element).val();
        if (!$.trim(value).length) {
            empty_inputs.push($(element));
            allFilled = false;
        }
    });

    if (empty_inputs.length > 1) {
        $.each(empty_inputs, function (index, element) {
            if (index < empty_inputs.length - 1) {
                $(element).closest('.form-group').remove();
            }
        });
    }
    if (inputs.length < 5 && allFilled) {
        var cloned = $(this).closest('.form-group').clone();
        cloned.find("input").val("");
        cloned.appendTo($(this).closest('.form-group').parent());
    }
});
$('.online-additional-website input').trigger('blur');
/* eof Benutzerprofil */

$('.stacktable').stacktable();

var $sidebar = $('.first-sidebar-element');

if ($sidebar.length) {
    $(window).on('resize scroll', function () {
        if (getWidth() >= 992) {
            $(function () {
                var offset = $sidebar.offset();
                var topPadding = 55;
                if ($(window).scrollTop() > offset.top) {
                    $sidebar.stop().animate({
                        paddingTop: $(window).scrollTop() - offset.top + topPadding + 'px'
                    });
                } else {
                    $sidebar.stop().animate({
                        paddingTop: 0 + 'px'
                    });
                }
            });
        } else {
            $sidebar.removeAttr('style');
        }
    }).resize();
}

/*
 Codemirror Initialisierung
 */
if($('#faq-content').length > 0){
    CodeMirror.fromTextArea(document.getElementById('faq-content'), {
        lineNumbers: true,
        mode: "htmlmixed"
    });
}

if($('.codeMirrorHtml').length>0){
    $.each(document.getElementsByClassName('codeMirrorHtml'),function($key,$value){
        CodeMirror.fromTextArea($value, {
            lineNumbers:true,
            mode: "htmlmixed"
        });
    });
}

/*
Sonderprovisionen
*/
$('.backend-users-specialprovision form button.add-step').on('click',function(){
    var template = $('#form-group-template').html();
    $('.special-provision-steps').append(template);
});
$('.backend-users-specialprovision form').delegate('button.remove-step','click',function(){
    var $this = $(this);
    $this.parentsUntil('.row').parent().remove();
});

/*
EOF Sonderprovisionen
*/

/*
Popup
*/
$('[data-popup]').click(
    function (e) {
        e.preventDefault();
        var $popup = {};
        $popup.name = $(this).data('popup');
        $popup.$content = $('[data-is-popup="' + $popup.name + '"]');
        if ($popup.$content.length == 1) {
            $popup.$content.removeClass("hidden");
            $popup.$content.closeBtn = $popup.$content.find(".close-btn");
            $popup.$content.close = function () {
                $popup.$content.addClass("hidden");
            }
            $popup.$content.on("click", ".close-btn", function () {
                $popup.$content.close();
            });
        } else {
            return false;
        }
    }
);
