/*
 |--------------------------------------------------------------------------
 | Autocompleter Funktionen für diverse Bereiche
 |--------------------------------------------------------------------------
 */

/**
 * Updated anhand der PLZ die Stadt und die Straßen
 * Man kann per options noch einige Callbacks angeben
 *
 * @param object options
 */
var autocompleteCityAndStreetsByPostcode = function(options) {
    options = $.extend({
        cityFail: $.noop,
        streetFail: $.noop,
        citySuccess: $.noop,
        streetSuccess: $.noop,
        postcode: $('#field_postleitzahl_abweichend'),
        city: $('#field_wohnort_abweichend'),
        street: $('#field_strasse_abweichend')
    }, options);

    var $postcode = options.postcode;
    var $city = options.city;
    var $street = options.street;

    var $cityError = $city.closest(".form-field").find(".form-field-error > small:first");
    var $streetError = $street.closest(".form-field").find(".form-field-error > small:first");

    var prepareElementsForDataRefresh = function() {
        $cityError.empty();
        $streetError.empty();

        $city.find('option').remove();
        $city.prop('disabled', true);
        $street.find('option').not(':first').remove();
        $street.prop('disabled', true);
        // SelectboxIt muss refresht werden, untere funktioniert nicht
        //$street.data("selectBox-selectBoxIt").refresh();
    };

    var allowOnlyValidPostcode = function() {
        var postcode = $postcode.val();

        // Nur Zahlen
        postcode = postcode.replace( /[^0-9]+/g, '');

        // Nicht länger als 5 Zeichen
        if (postcode.length > 5) {
            postcode = postcode.substr(0, 5);
        }

        $postcode.val(postcode);
    };

    var refreshCity = function() {
        apiGet('shared/v1/autocomplete/cities/multi', {
            postcode: $postcode.val()
        }).then(function (response) {
            $city.prop('disabled', false);
            var $data = response.data;
            updateSelectboxWithData($city, $data, $city.data('selected'));
            options.streetSuccess(response);
            $('select[name="city"]').trigger('change');
            $('select[name="billing_city"]').trigger('change');
        }).fail(function (xhr) {
            response = xhrToJson(xhr);
            $cityError.html(response.message);
            options.cityFail(response);
        });
    };

    var refreshStreets = function() {
        apiGet('shared/v1/autocomplete/streets', {
            postcode: $postcode.val()
        }).then(function (response) {
            $street.prop('disabled', false);
            var $data = response.data;
            if ($('select[name="street"]').data('street-extra') || $('select[name="billing_street"]').data('street-extra')) {
                $data.push({
                    id: 'street_extra',
                    name: 'Sonstige'
                });
            }
            updateSelectboxWithData($street, $data, $street.data('selected'));
            options.streetSuccess(response);
            $('select[name="street"]').trigger('change');
            $('select[name="billing_street"]').trigger('change');
        }).fail(function (xhr) {
            response = xhrToJson(xhr);
            $streetError.html(response.message);
            options.streetFail(response);
        });
    };

    var refreshData = function() {
        prepareElementsForDataRefresh();
        allowOnlyValidPostcode();
        if ($postcode.val().length === 5) {
            refreshCity();
            refreshStreets();
        } else {
            $city.append($('<option/>', {
                value: '',
                text : 'Bitte Stadt angeben',
            }));
            $('select[name="city"]').trigger('change');
            $('select[name="billing_city"]').trigger('change');
        }
    };

    $postcode.on('blur', function(event) {
        refreshData();
    });
    refreshData();
};


var autocompleteBankname = function() {
    $iban = $('input[name="iban"]');
    $bic = $('input[name="bic"]');
    $bank = $('input[name="bank"]');

    var refreshData = function() {
        apiGet('shared/v1/autocomplete/bank', {
            iban: $iban.val()
        }).then(function (response) {
            console.log(response.data);
            $bank.val(response.data.name);
            $bic.val(response.data.bic)
        });
    };

    $iban.on('blur', function(event) {
        refreshData();
    });
};
